const deyimAnlamListesi = [
  {
    deyim: "Ağzını aramak",
    anlam: "konuşturarak düşüncesini öğrenmeye çalışmak",
  },
  { deyim: "Çileden çıkarmak", anlam: "çok kızdırmak" },
  { deyim: "Dert yanmak", anlam: "bir şeyden şikâyet etmek, yakınmak" },
  { deyim: "Sinirine dokunmak", anlam: "hoşuna gitmemek, sinirlendirmek" },
  {
    deyim: "Kulağına inanmamak",
    anlam: "duyduklarının doğruluğundan şüphe etmek",
  },
  {
    deyim: "Dili dönmemek",
    anlam: "bir sözü doğru, düzgün söylemeyi becerememek",
  },
  { deyim: "Eli silah tutmak", anlam: "silah kullanabilmek" },
  {
    deyim: "Neye uğradığını şaşırmak",
    anlam:
      "ansızın üzücü, sıkıcı, neşeli, güzel veya hoş bir durumla karşılaşmak",
  },
  {
    deyim: "Sokağa dökülmek",
    anlam: [
      "herhangi bir sebeple dışarı çıkmak",
      "gösteri, protesto gibi amaçlarla insanlar sokaklara, meydanlara inmek.",
    ],
  },
  {
    deyim: "Demir almak",
    anlam: [
      "denizcilik gemi yola çıkmak için çıpasını denizden çekmek, gitmeye hazırlanmak",
      "mecaz ölmek, çekip gitmek",
      "mecaz sıvışmak, gitmek",
    ],
  },
  { deyim: "Ablukaya almak", anlam: "kuşatmak" },
  {
    deyim: "Çaba harcamak",
    anlam: "bir işi yapabilmek için elinden geleni yapmak",
  },
  {
    deyim: "Gözünü dört açmak",
    anlam: "aldanmamak için çok uyanık bulunmak",
  },
  {
    deyim: "Ne pahasına olursa olsun",
    anlam: [
      "ne büyük özveri isterse istesin",
      "her türlü sıkıntı ve tehlikeyi göze almak",
    ],
  },
  {
    deyim: "Hop oturup hop kalkmak",
    anlam:
      "öfke, heyecan vb. duygular sebebiyle yerinde duramaz olmak, kalkıp kalkıp oturmak",
  },
  {
    deyim: "Yola çıkmak",
    anlam: [
      "araca binmek üzere yol üstünde durmak",
      "bir yere varmak için bulunduğu yerden ayrılarak yolculuğa başlamak, harekete geçmek",
      "herhangi bir şeyi esas almak, oradan başlamak",
    ],
  },
  { deyim: "Nam salmak", anlam: "ününü her yana yaymak" },
  {
    deyim: "Ateşe vermek",
    anlam: [
      "-i ateş içine sokmak",
      "bir yeri kasten yakmak, kundak sokmak",
      "(mecaz) aşırı telaşa ve sıkıntıya düşürmek",
      "(mecaz) bir ülkeyi savaşa sokarak veya kargaşa ve karışıklık yaratarak sıkıntı ve yıkıma uğratmak",
    ],
  },
  {
    deyim: "Göz yummak",
    anlam: [
      "görmezlikten gelmek, hoş görmek, bağışlamak",
      "umudunu kesmek, umutsuzluğa düşmek",
    ],
  },
  {
    deyim: "Haklı çıkmak",
    anlam:
      "davasının, iddiasının, düşüncesinin veya davranışının doğru olduğu anlaşılmak",
  },
  { deyim: "İçinden geçirmek", anlam: "bir şeyi yapmayı düşünmek" },
  { deyim: "Zoruna gitmek", anlam: ["onuruna dokunmak, gücüne gitmek"] },
  { deyim: "Önem vermek", anlam: "değer vermek, önemli saymak" },
  { deyim: "Burnunu sokmak", anlam: "gerekmeden her işe karışmak" },
  { deyim: "Nefes nefese kalmak", anlam: "soluğu tıkanacak gibi olmak" },
  { deyim: "Uzak kalmak", anlam: "uzakta bulunmak" },
  { deyim: "İlgi göstermek", anlam: "ilgisini esirgememek, belli etmek" },
  { deyim: "Özlemini duymak", anlam: "yürekten istemek, arzu etmek" },
  {
    deyim: "Yer tutmak",
    anlam: [
      "yer ayırmak",
      "yer kaplamak",
      "işlevi ve etkisi olmak",
      "önemli sayılmak, önemi olmak",
    ],
  },
  {
    deyim: "Baş başa kalmak",
    anlam: "biriyle veya bir şeyle yalnız kalmak",
  },
  {
    deyim: "Dili çözülmek",
    anlam: "konuşamayan veya susan kişi konuşmaya başlamak",
  },
  { deyim: "Şahit olmak", anlam: "tanık olmak" },
  { deyim: "Yenik düşmek", anlam: "yenilmek, mağlup olmak" },
  {
    deyim: "Kulak misafiri olmak",
    anlam: "yanında konuşulanları konuşmaya katılmadan dinlemek",
  },
  {
    deyim: "Sabrı taşmak",
    anlam: "artık katlanamaz, dayanamaz duruma gelmek, sabrı kalmamak",
  },
  { deyim: "Galeyana gelmek", anlam: "coşmak, hiddetlenmek" },
  { deyim: "Esir düşmek", anlam: "tutsak olmak" },
  { deyim: "Yola düşmek", anlam: "yola çıkmak, yol almaya başlamak" },
  {
    deyim: "Baskın yapmak",
    anlam: [
      "suç işlendiği veya suçluların bulunduğu sanılan bir yere ansızın girmek",
      "düşmana ansızın saldırmak",
      "(mecaz) ansızın konuk gelmek",
    ],
  },
  {
    deyim: "Zarar vermek",
    anlam: ["kötülük etmek", "birinin parasal kayba uğramasına sebep olmak"],
  },
  { deyim: "Demir atmak", anlam: "bir kimse bir yerde uzun süre kalmak" },
  {
    deyim: "Boy vermek",
    anlam: [
      "su insan boyunu aşacak kadar derin olmak",
      "suya dalarak boyu ile suyun derinliğini ölçmek",
      "büyümek",
    ],
  },
  { deyim: "Kilit vurmak", anlam: "kapatmak" },
  {
    deyim: "Birbirine girmek",
    anlam: [
      "karışmak",
      "iplik vb. dolaşmak, çözülmeyecek duruma gelmek",
      "birçok araç bir anda çarpışmak",
      "(mecaz) kavga etmek, dövüşmek",
    ],
  },
  { deyim: "Saçına ak düşmek", anlam: "saçı ağarmaya başlamak, yaşlanmak" },
  {
    deyim: "Kıyamet kopmak",
    anlam: "bir yerde çok gürültü ve telaş olmak",
  },
  { deyim: "Tahta çıkmak", anlam: "hükümdar olmak" },
  { deyim: "Boğazı düğümlenmek", anlam: "üzüntüden boğazı tıkanmak" },
  {
    deyim: "İçine sinmek",
    anlam: [
      "isteğince olduğu için huzur ve mutluluk duymak",
      "içi rahat etmek",
    ],
  },
  {
    deyim: "Kendini alamamak",
    anlam: "istemeyerek bir işi yapma durumuna girmek",
  },
  {
    deyim: "Gözlerine inanamamak",
    anlam:
      "hiç umulmayan, hiç beklenmeyen bir şeyin görülmesi karşısında şaşırmak",
  },
  {
    deyim: "Kendine gelmek",
    anlam: ["ayılmak", "aklı başına gelmek", "durumu düzelmek"],
  },
  {
    deyim: "Gözleri takılıp kalmak",
    anlam: "bir şeyden gözlerini ayıramamak",
  },
  { deyim: "Ödü patlamak", anlam: "çok korkmak" },
  {
    deyim: "Kara kara düşünmek",
    anlam: "çok üzüntülü olmak, düşünceye dalmak",
  },
  { deyim: "Elinden kurtulmak", anlam: "birinden kaçmayı başarmak" },
  {
    deyim: "Gözden kaybolmak",
    anlam: "ortadan çekilmek veya görünmez olmak, kaybolmak",
  },
  {
    deyim: "Burun kıvırmak",
    anlam: "önem vermemek, küçümsemek, beğenmemek",
  },
  {
    deyim: "Gözden düşmek",
    anlam: "bir kişi veya şey değerini yitirmek, rağbet görmemek",
  },
  { deyim: "Kanı donmak", anlam: "donakalmak, çok şaşırmak" },
  { deyim: "Bir deri bir kemik kalmak", anlam: "çok zayıf olmak" },
  {
    deyim: "Göz göze gelmek",
    anlam: "her iki tarafın bakışları karşılaşmak",
  },
  {
    deyim: "Kulağına küpe olmak",
    anlam: "başa gelen bir durumdan alınan dersi unutmamak",
  },
  { deyim: "Kulak kabartmak", anlam: "belli etmemeye çalışarak dinlemek" },
  {
    deyim: "Canına tak etmek",
    anlam: "dayanamaz duruma gelmek, sabrı kalmamak",
  },
  {
    deyim: "Dile gelmek",
    anlam: [
      "konuşma kudreti, yeteneği, olmayan varlık konuşmak",
      "dillenmek, lisana gelmek",
    ],
  },
  { deyim: "Kıymetini bilmek", anlam: "önemini, değerini bilmek" },
  { deyim: "El atmak", anlam: "birisinin işine karışmak, müdahale etmek" },
  {
    deyim: "El sürmemek",
    anlam: ["dokunmamak, değmemek", "bir işi yapmamak, ilgilenmemek"],
  },
  {
    deyim: "Elini eteğini çekmek",
    anlam: [
      "uğramaz olmak",
      "uğraşmamak, ilgilenmemek",
      "o şeyle ilgisini kesmek",
    ],
  },
  {
    deyim: "Aşağı kalmamak",
    anlam: "herhangi bir nitelik bakımından geri olmamak",
  },
  {
    deyim: "Meydan okumak",
    anlam:
      "korkmadığını, çekinmediğini açıkça bildirmek, kavga veya yarışmaya çağırmak",
  },
  { deyim: "Boy ölçüşmek", anlam: "yarışmak" },
  { deyim: "Karşı koymak", anlam: "boyun eğmemek" },
  {
    deyim: "Gücü yetmek",
    anlam: [
      "eldeki imkânlarla ancak altından kalkabilmek",
      "üstesinden gelebilmek",
      "üstesinden gelebilir olmak",
    ],
  },
  {
    deyim: "Gayret göstermek",
    anlam: "çaba harcamak, başarmak için çalışmak",
  },
  {
    deyim: "Yardıma koşmak",
    anlam: "güç duruma düşene istekle yardım etmek",
  },
  {
    deyim: "Hakkı ödenmemek",
    anlam:
      "birinin iyiliklerine, emeklerine karşılık olarak ne yapılsa az olmak",
  },
  {
    deyim: "Kapı kapı dolaşmak",
    anlam: [
      "ev ev gezmek",
      "bir yerlere sürekli girip çıkmak",
      "iş aramak için her yere başvurmak",
    ],
  },
  { deyim: "Çaresiz kalmak", anlam: "çözüm yolu, çıkar yolu bulamamak" },
  { deyim: "Kâr etmemek", anlam: "yararı olmamak, etki yapmamak" },
  {
    deyim: "Dile getirmek",
    anlam: ["konuşturmak", "belirtmek, anlatmak, açıklamak, ifade etmek"],
  },
  { deyim: "Yol açmak", anlam: "(mecaz) bir olayın sebebi olmak" },
  {
    deyim: "Ortaya çıkmak",
    anlam: [
      "yokken var olmak, meydana çıkmak, türemek",
      "biri kendini göstermek",
    ],
  },
  { deyim: "Mutlu etmek", anlam: "mutluluk vermek, bahtiyar etmek" },
  {
    deyim: "Merak sarmak",
    anlam:
      "bir şeyi edinme, yapma veya onunla uğraşma isteğine kapılmak, bir şeye eğilim duymak",
  },
  {
    deyim: "Karar vermek",
    anlam: "bir sorunu karara bağlamak, kararlaştırmak",
  },
  {
    deyim: "Ağzını bıçak açmamak",
    anlam: "üzüntüden söz söyleyemeyecek durumda olmak",
  },
  { deyim: "Yüzü kızarmak", anlam: "utanmak" },
  {
    deyim: "Karar vermek",
    anlam: "bir sorunu karara bağlamak, kararlaştırmak",
  },
  { deyim: "Şifa bulmak", anlam: "iyi olmak, onmak" },
  {
    deyim: "Kusur etmemek",
    anlam: "hoş karşılanmayacak bir davranışta bulunmamak",
  },
  {
    deyim: "Yola çıkmak",
    anlam:
      "bir yere varmak için bulunduğu yerden ayrılarak yolculuğa başlamak, harekete geçmek",
  },
  { deyim: "Zevk almak", anlam: "hoşlanmak, beğenmek" },
  { deyim: "Ava çıkmak", anlam: "avlanmak için gitmek" },
  { deyim: "Haber vermek", anlam: "bildirmek, haber ulaştırmak" },
  {
    deyim: "Karşılık vermek",
    anlam: ["küçük büyüğüne karşı gelmek", "cevap vermek, yanıt vermek"],
  },
  {
    deyim: "Şaşırıp kalmak",
    anlam: "çok şaşırmak, büyük bir şaşkınlığa düşmek",
  },
  {
    deyim: "Hüküm vermek",
    anlam: [
      "iyice düşündükten sonra bir karara varmak",
      "bir suçluyu mahkûm etme",
    ],
  },
  {
    deyim: "Arkasına düşmek",
    anlam: [
      "bir işi sona erdirmek için sıkı çalışmak",
      "birini gözden ayırmayarak arkasından gitmek",
    ],
  },
  {
    deyim: "Ağırdan almak",
    anlam: [
      "bir işi gereken süre içinde bitirmemek, geciktirmek",
      "bir işi gönülsüz, isteksiz yapmak",
    ],
  },
  {
    deyim: "Kendini dev aynasında görmek",
    anlam: "kendini olduğundan çok üstün görmek",
  },
  {
    deyim: "Rüyasında bile görememek",
    anlam: "olacağını, gerçekleşeceğini düşünememek",
  },
  { deyim: "Gözüne çarpmak", anlam: "görünür olmak, dikkati çekmek" },
  {
    deyim: "Burun kıvırmak",
    anlam: "önem vermemek, küçümsemek, beğenmemek",
  },
  { deyim: "Göz dikmek", anlam: "bir şeyi ele geçirmek isteğine kapılmak" },
  {
    deyim: "Gözden geçirmek",
    anlam: [
      "okumak",
      "niteliğini anlamak için bir şeyin her yanına bakmak, incelemek, muayene etmek",
      "araç, motor vb.nin çalışıp çalışmadığını incelemek, denemek, denetlemek",
    ],
  },
  { deyim: "Gözden kaçırmak", anlam: "dalgınlıkla görmemek" },
  {
    deyim: "Yola getirmek",
    anlam: "birinin bir konudaki ters tutumunu düzeltmek",
  },
  {
    deyim: "Yoldan çıkmak",
    anlam: [
      "belli bir yol izleyen taşıtlar herhangi bir sebeple yolundan ayrılmak, gitmez olmak",
      "(mecaz) doğru yoldan ayrılmak.",
    ],
  },
  { deyim: "Bir yolunu bulmak", anlam: "çare bulmak, çözüm üretmek" },
  { deyim: "İç çekmek", anlam: "üzüntüyle derinden soluk almak" },
  {
    deyim: "İş işten geçmek",
    anlam: "bir işi gerçekleştirme imkânı kalmamış olmak",
  },
  { deyim: "Gönlü razı olmak", anlam: "istemek" },
  { deyim: "Karnı zil çalmak", anlam: "çok acıkmış olmak" },
  {
    deyim: "Bahane bulmak",
    anlam: "bir işi yapmak veya yapmamak için sözde sebep göstermek",
  },
  {
    deyim: "Ders vermek",
    anlam: [
      "öğretmek, yetiştirmek",
      "azarlamak, sert davranmak, sert bir karşılıkla yola getirmek",
    ],
  },
  { deyim: "Akıl danışmak", anlam: "bir konuda birinin görüşünü sormak" },
  { deyim: "Göçüp gitmek", anlam: "ölmek" },
  {
    deyim: "Yol göstermek",
    anlam: [
      "kılavuzluk etmek, yolu bilmeyene anlatmak, tarif etmek",
      "(mecaz) ne yapılacağını, nasıl davranılacağını öğretmek",
    ],
  },
  {
    deyim: "Işık tutmak",
    anlam: [
      "bir yeri ışıkla aydınlatmak",
      "(mecaz) düşüncesiyle kılavuzluk etmek, konuyu aydınlatıcı düşünceler söylemek, tutacağı yolu göstermek",
    ],
  },
  {
    deyim: "Bağrına taş basmak",
    anlam: "sesini çıkarmaksızın her türlü acıya katlanmak",
  },
  { deyim: "Kafa tutmak", anlam: "boyun eğmemek, karşı gelmek, diklenmek" },
  { deyim: "Donup kalmak", anlam: "donakalmak" },
  {
    deyim: "Eline geçmek",
    anlam: ["kazanmak, edinmek, elde etmek", "rastlamak, bulmak", "yakalamak"],
  },
  {
    deyim: "Ayakta kalmak",
    anlam: [
      "oturacak yer bulamamak",
      "(mecaz) yıkılmamak, çökmemek",
      "(mecaz) değerini yitirmemek, önemini korumak",
    ],
  },
  {
    deyim: "Dile gelmek",
    anlam: [
      "dile düşmek",
      "konuşma kudreti, yeteneği, olmayan varlık konuşmak, dillenmek, lisana gelmek",
    ],
  },
  { deyim: "Meydana getirmek", anlam: "olmasını sağlamak, oluşturmak" },
  {
    deyim: "Rast gelmek",
    anlam: [
      "düşünmediği, ummadığı hâlde karşılaşmak, rastlamak, tesadüf etmek",
      "düşünmediği veya düşülmediği hâlde payına düşmek",
      "atılan şey hedefi bulmak",
    ],
  },
  {
    deyim: "İmdadına yetişmek",
    anlam: "çok zor ve tehlikeli bir anda yardım etmek",
  },
  { deyim: "Yola düzülmek", anlam: "gidilecek yere doğru yola çıkmak" },
  {
    deyim: "Salık vermek",
    anlam: ["tavsiye etmek", "(eskimiş) haber vermek"],
  },
  { deyim: "İçi cız etmek", anlam: "ansızın içi sızlamak" },
  { deyim: "Görmezlikten gelmek", anlam: "görmemiş gibi davranmak" },
  { deyim: "Karşı koymak", anlam: "boyun eğmemek" },
  {
    deyim: "Kement atmak",
    anlam: "kemendi bir ucu elde kalacak biçimde ileri doğru fırlatmak",
  },
  {
    deyim: "Özen göstermek",
    anlam:
      "bir şeyi özenerek elden geldiğince iyi olmasına gayret ederek yapmak, itina etmek",
  },
  {
    deyim: "Canını dişine takmak",
    anlam: [
      "her tehlikeyi göze alarak işe girişmek",
      "bütün gücünü harcayarak yapmak.",
    ],
  },
  { deyim: "Dışa vurmak", anlam: "belli etmek" },
  {
    deyim: "Sıcak bakmak",
    anlam: "anlayışla karşılamak, olumlu değerlendirmek, ilgi duymak",
  },
  {
    deyim: "Kendini kaptırmak",
    anlam: [
      "bir şeyin etkisinden kurtulamayacak duruma düşmek",
      "uğraşmaya başladığı bir işten kendini kurtaramamak",
    ],
  },
  {
    deyim: "Aldırış etmemek",
    anlam: "ilgi göstermemek, ilgilenmemek, ilgisiz kalmak",
  },
  { deyim: "Hapı yutmak", anlam: "kötü bir duruma düşmek" },
  {
    deyim: "Açıklığa kavuşmak",
    anlam:
      "bir konu veya sorun aydınlanmak, kapalılıktan kurtarılmak, anlaşılır duruma getirilmek",
  },
  {
    deyim: "Sözünü etmek",
    anlam: "birinden veya bir konudan söz etmek, onunla ilgili olarak konuşmak",
  },
  { deyim: "Sözü uzatmak", anlam: "lafı uzatmak" },
  {
    deyim: "İliklerimize işlemek",
    anlam: [
      "çok ıslanmak",
      "çok üşümek",
      "bütün varlığını kaplamak, çok etkilenmek",
    ],
  },
  {
    deyim: "Burnunun dibine sokulmak",
    anlam: "çok yaklaşmak, iyice yaklaşmak",
  },
  {
    deyim: "Baş başa kalmak",
    anlam: "biriyle veya bir şeyle yalnız kalmak",
  },
  { deyim: "Fırsat bulmak", anlam: "uygun, elverişli zaman bulmak" },
  { deyim: "Nasihatte bulunmak", anlam: "nasihat etmek" },
  { deyim: "Son vermek", anlam: "bitirmek, sona erdirmek" },
  { deyim: "İçi cız etmek", anlam: "ansızın içi sızlamak" },
  { deyim: "Söze karışmak", anlam: "lafa karışmak" },
  {
    deyim: "Elden geçirmek",
    anlam:
      "eksiklik veya bozukluklarını gidermek veya denetlemek için incelemek",
  },
  {
    deyim: "Bağrına basmak",
    anlam: [
      "kucaklamak",
      "-den biriyle ilgilenerek onu koruyup kayırmak, yetiştirmek",
    ],
  },
  { deyim: "Gözünün önüne gelmek", anlam: "hatırlamak" },
  { deyim: "Bıkıp usanmak", anlam: "çok bezmek" },
  { deyim: "Haber uçurmak", anlam: "gizlice haber göndermek" },
  { deyim: "Hafife almak", anlam: "küçümsemek, önemsememek" },
  { deyim: "Hasret çekmek", anlam: "özlem duymak" },
  {
    deyim: "Dili varmamak",
    anlam: "bir sözü söylemeye gönlü razı olmamak",
  },
  {
    deyim: "Eline geçmek",
    anlam: ["kazanmak, edinmek, elde etmek", "rastlamak, bulmak", "yakalamak"],
  },
  { deyim: "Kendini avutmak", anlam: "oyalanmak" },
  {
    deyim: "Moral vermek",
    anlam:
      "bir kimsenin ruhsal direnme gücünü artırmak, cesaretlendirmek, yüreklendirmek",
  },
  { deyim: "Aklından çıkmak", anlam: "unutmak" },
  { deyim: "Gözünden yaş boşanmak", anlam: "çok ağlamak" },
  { deyim: "Uykuya dalmak", anlam: "uyumaya başlamak" },
  {
    deyim: "Araya girmek",
    anlam: [
      "iki kişinin arasındaki bir işe karışmak",
      "iki kişiyi uzlaştırmaya çalışmak",
      "bir iş yapılırken ona engel olacak başka bir şey çıkmak",
    ],
  },
  {
    deyim: "Yol göstermek",
    anlam: [
      "kılavuzluk etmek, yolu bilmeyene anlatmak, tarif etmek",
      "(mecaz) ne yapılacağını, nasıl davranılacağını öğretmek",
    ],
  },
  {
    deyim: "Karar vermek",
    anlam: "bir sorunu karara bağlamak, kararlaştırmak",
  },
  { deyim: "Rahatı kaçmak", anlam: "rahatsız, tedirgin olmak, üzülmek" },
  {
    deyim: "Dolup taşmak",
    anlam: [
      "gereğinden çok olmak, gereğinden çok kaplamak",
      "çok kalabalık olmak",
    ],
  },
  {
    deyim: "Göbek atmak",
    anlam: ["karnını hareket ettirerek oynamak", "(mecaz) çok sevinmek"],
  },
  {
    deyim: "Kapı kapı gezmek",
    anlam: [
      "ev ev gezmek",
      "bir yerlere sürekli girip çıkmak",
      "iş aramak için her yere başvurmak",
    ],
  },
  {
    deyim: "Açık kapı bırakmak",
    anlam: [
      "gereğinde, bir konuya yeniden dönebilme imkânı bırakmak",
      "kesip atmamak",
    ],
  },
  { deyim: "Kapı dışarı etmek", anlam: "kovmak, dışarı atmak" },
  {
    deyim: "Kapıdan kovsan bacadan girer",
    anlam: "yüzsüz, arsız kimseler için söylenen bir söz",
  },
  {
    deyim: "Kafasına koymak",
    anlam: [
      "kararını önceden vermiş olmak",
      "önceden şartlanmak",
      "bir şey yapmaya kesin karar vererek zamanını beklemek",
    ],
  },
  {
    deyim: "Ortaya koymak",
    anlam: [
      "herkesin görebileceği yere koymak",
      "yaratmak, yapmak",
      "açıklamak",
    ],
  },
  {
    deyim: "Hayata geçirmek",
    anlam: "uygulanır duruma getirmek, canlılık kazandırmak",
  },
  { deyim: "Gözüne uyku girmemek", anlam: "uyuyamamak, uykusuz kalmak" },
  { deyim: "Gözüne ilişmek", anlam: "birdenbire, istemeden görmek" },
  {
    deyim: "Işık tutmak",
    anlam: [
      "bir yeri ışıkla aydınlatmak",
      "(mecaz) düşüncesiyle kılavuzluk etmek, konuyu aydınlatıcı düşünceler söylemek, tutacağı yolu göstermek",
    ],
  },
  {
    deyim: "Aklından geçirmek",
    anlam: "bir şeyi yapmayı düşünmek, tasarlamak",
  },
  {
    deyim: "Karşılık vermek",
    anlam: ["küçük büyüğüne karşı gelmek", "cevap vermek, yanıt vermek"],
  },
  { deyim: "Kafa tutmak", anlam: "boyun eğmemek, karşı gelmek, diklenmek" },
  {
    deyim: "Kendine gelmek",
    anlam: ["ayılmak", "aklı başına gelmek", "durumu düzelmek"],
  },
  {
    deyim: "Hüküm sürmek",
    anlam: [
      "işbaşında olmak",
      "yaygın olmak",
      "etki, hız vb. sürmek, devam etmek",
    ],
  },
  {
    deyim: "Gözlerine inanamamak",
    anlam:
      "hiç umulmayan, hiç beklenmeyen bir şeyin görülmesi karşısında şaşırmak",
  },
  {
    deyim: "Yüzü gülmek",
    anlam: [
      "sevinci yüzünden belli olmak",
      "feraha kavuşmak",
      "temiz, tertipli duruma gelmek",
    ],
  },
  { deyim: "İşi yoluna koymak", anlam: "işi yapılabilir duruma getirmek" },
  { deyim: "Neşesi kaçmak", anlam: "sevinci azalmak, kederlenmek" },
  {
    deyim: "Sesini çıkarmamak",
    anlam: "bir şey üzerindeki düşüncesini söylememek",
  },
  {
    deyim: "Düş kırıklığına uğramak",
    anlam: "beklediği sonucu alamamak",
  },
  { deyim: "Çekip gitmek", anlam: "bırakıp gitmek, ayrılmak, savuşmak" },
  {
    deyim: "Dalıp gitmek",
    anlam: "bir düşünce veya hayal ile bulunduğu ortamdan uzaklaşmak",
  },
  { deyim: "Anlaşmaya varmak", anlam: "bir konuda birisiyle anlaşmak" },
  {
    deyim: "Can vermek",
    anlam: [
      "ölmek",
      "ruha güç vermek",
      "canlanmasına yol açmak",
      "bir şeyi çok istemek",
    ],
  },
  {
    deyim: "Gözden geçirmek",
    anlam: [
      "okumak",
      "niteliğini anlamak için bir şeyin her yanına bakmak, incelemek, muayene etmek",
      "araç, motor vb.nin çalışıp çalışmadığını incelemek, denemek, denetlemek",
    ],
  },
  {
    deyim: "Yola çıkmak",
    anlam: [
      "araca binmek üzere yolüstünde durmak",
      "bir yere varmak için bulunduğu yerden ayrılarak yolculuğa başlamak, harekete geçmek",
      "herhangi bir şeyi esas almak, oradan başlamak",
    ],
  },
  {
    deyim: "Yüz tutmak",
    anlam: [
      "bir şey, olmak üzere bulunmak",
      "giderek biçim ve renk değiştirmek",
    ],
  },
  {
    deyim: "Patlak vermek",
    anlam:
      "gizli kalması istenen veya beklenmedik bir olay, ansızın ortaya çıkmak",
  },
  {
    deyim: "İlgi duymak",
    anlam: "bir işe, bir olaya, bir kimseye önem vermek, yakınlık duymak",
  },
  { deyim: "Küçük görmek", anlam: "değer, önem vermemek" },
  {
    deyim: "Omuzları çökmek",
    anlam: "bitkin, perişan ve yıkılmış bir durumda olmak",
  },
  {
    deyim: "İhmal etmek",
    anlam: [
      "savsamak, savsaklamak, boşlamak",
      "birine gereken ilgiyi göstermemek",
    ],
  },
  {
    deyim: "Dikkat kesilmek",
    anlam: "bütün dikkatini bir şey üzerinde toplamak",
  },
  { deyim: "Mahşere dönmek", anlam: "çok kalabalıklaşmak" },
  {
    deyim: "Oluruna bırakmak",
    anlam: [
      "işi belli bir amaca göre değil, kendi akışı içinde yürütmek",
      "sonucu önemsemeyerek bir işin yapılabildiği, olabildiği kadarıyla yetinmek",
    ],
  },
  {
    deyim: "Başının etini yemek",
    anlam:
      "karşısındakini bezdirinceye, bıktırıncaya kadar sürekli konuşmak veya söylenmek",
  },
  { deyim: "Kayıtsız kalmak", anlam: "önem vermemek, umursamamak" },
  {
    deyim: "Kapıya dayanmak",
    anlam: [
      "gelip çatmak",
      "bir şey elde etmek için bir yeri, bir kimseyi zorlamak, göz korkutmak",
    ],
  },
  { deyim: "İşi gücü bırakmak", anlam: "yaptığı işten uzaklaşmak" },
  {
    deyim: "Ele almak",
    anlam: [
      "bir şey üzerinde çalışmaya başlamak",
      "bir konuyu görüşmek",
      "bir konuyu incelemek, araştırmak",
      "herhangi bir şeyi iş edinmek",
    ],
  },
  { deyim: "Karşı gelmek", anlam: ["başkaldırmak", "birini karşılamak"] },
  {
    deyim: "Neye uğradığı şaşırmak",
    anlam:
      "ansızın üzücü, sıkıcı, neşeli, güzel veya hoş bir durumla karşılaşmak",
  },
  { deyim: "Can atmak", anlam: "şiddetle arzu etmek, çok istemek" },
  { deyim: "Son bulmak", anlam: "bitmek, tükenmek" },
  {
    deyim: "Deliye dönmek",
    anlam: ["çok sevinmek", "çok üzülmek", "çok kızmak"],
  },
  { deyim: "Korku salmak", anlam: "korkutmak" },
  {
    deyim: "Avucunun içine almak",
    anlam: "bir kimseyi baskı ve etkisi altına almak",
  },
  {
    deyim: "Salık vermek",
    anlam: ["tavsiye etmek", "(eskimiş) haber vermek"],
  },
  {
    deyim: "Öğüt vermek",
    anlam:
      "bir kimseye yapması veya yapmaması gereken şeyler için yol göstermek, nasihat etmek",
  },
  {
    deyim: "Sahip çıkmak",
    anlam: [
      "kendinin olduğunu ileri sürmek",
      "korumak, koruyucu olmak, ilgilenip gözetmek",
    ],
  },
  {
    deyim: "Yerini tutmak",
    anlam: [
      "bulunmayan bir nesnenin yerini almak, onu aratmamak",
      "görevinden ayrılan birinin yaptığı işi yapabilmek",
    ],
  },
  {
    deyim: "Dile getirmek",
    anlam: ["konuşturmak", "belirtmek, anlatmak, açıklamak, ifade etmek"],
  },
  {
    deyim: "Özlemini çekmek",
    anlam: "arzulamak, çok özlemek, hasretini çekmek",
  },
  {
    deyim: "Dili çözülmek",
    anlam: "konuşamayan veya susan kişi konuşmaya başlamak",
  },
  {
    deyim: "Gönül vermek",
    anlam: [
      "sevmek, âşık olmak",
      "bir şeyi sevmeye, istemeye veya yapmaya içten yönelmek, eğinmek, meyletmek",
      "düşkün olmak",
    ],
  },
  {
    deyim: "Elde etmek",
    anlam: [
      "bir şeye sahip olmak",
      "bir kimseyi kendi hizmetine almak veya kendinden yana çekmek",
    ],
  },
  {
    deyim: "Yüze gülmek",
    anlam: ["yalandan dost görünmek", "sevimli, alımlı görünmek"],
  },
  { deyim: "Yüreği sızlamak", anlam: "Çok acımak, çok üzülmek" },
  {
    deyim: "Baş başa bırakmak",
    anlam: "Birinin, bir şeyle veya bir kimseyle yalnız kalmasını sağlamak",
  },
  {
    deyim: "Yer açmak",
    anlam: [
      "Bir kimseye oturması için yer hazırlamak",
      "(mecaz) Yer bırakmak, imkân vermek",
    ],
  },
  {
    deyim: "Tadını çıkarmak",
    anlam:
      "Bir şeyin güzelliğinden veya sağladığı imkânlardan yeterince yararlanmak",
  },
  { deyim: "Kan tere batmak", anlam: "Kan ter içinde kalmak" },
  {
    deyim: "Adamdan saymak",
    anlam: "Bir kimseye gereğinden fazla değer vermek, saygı duymak",
  },
  {
    deyim: "Öne çıkmak",
    anlam: "Diğerlerinden daha iyi olmasından dolayı dikkat çekmek",
  },
  {
    deyim: "Ortaya çıkmak",
    anlam: [
      "Yokken var olmak, meydana çıkmak, türemek",
      "Biri kendini göstermek",
    ],
  },
  { deyim: "Yola koyulmak", anlam: "Yola düzülmek" },
  { deyim: "Göçüp gitmek", anlam: "Ölmek" },
  {
    deyim: "Göz atmak",
    anlam: "Kısa bir süre, fazla dikkat etmeden bakıvermek",
  },
  { deyim: "Peşine takmak", anlam: "Yanında götürmek" },
  { deyim: "Göz kesilmek", anlam: "Bütün dikkatiyle bakmak" },
  {
    deyim: "Pabucu dama atılmak",
    anlam: "Kendinden üstün birinin çıkmasıyla gözden düşmek",
  },
  { deyim: "Etekleri tutuşmak", anlam: "Çok telaşlanmak" },
  {
    deyim: "Elini ayağını çekmek",
    anlam: [
      "Uğramaz olmak",
      "Uğraşmamak, ilgilenmemek",
      "O şeyle ilgisini kesmek",
    ],
  },
  {
    deyim: "Saçını süpürge etmek",
    anlam: "Kadın özveri ile çalışıp hizmet etmek",
  },
  {
    deyim: "Ele almak",
    anlam: [
      "Bir şey üzerinde çalışmaya başlamak",
      "Bir konuyu görüşmek",
      "Bir konuyu incelemek, araştırmak",
      "Herhangi bir şeyi iş edinmek",
    ],
  },
  {
    deyim: "Alışkanlık haline getirmek",
    anlam: "Bir şeyi sürekli yapar olmak",
  },
  { deyim: "Ciddiye almak", anlam: "İnanmak, gerçek sanmak, önem vermek" },
  {
    deyim: "Katkıda bulunmak",
    anlam:
      "Bir şeyin oluşmasına, gelişmesine veya gerçekleşmesine emek, bilgi, para vb. ile yardım etmek",
  },
  {
    deyim: "Göze almak",
    anlam: "Gelebilecek her türlü zararı ve tehlikeyi önceden kabul etmek",
  },
  {
    deyim: "Batağa saplanmak",
    anlam: "İçinden çıkılması güç bir durumda olmak",
  },
  { deyim: "Göz ardı etmek", anlam: "Gereken önemi vermemek" },
  {
    deyim: "Öfkesini çıkarmak",
    anlam: "Öfkeli kişi haksız yere ilgisiz birine çatmak",
  },
  { deyim: "Ödü kopmak", anlam: "Çok korkmak" },
  {
    deyim: "İhtimal vermemek",
    anlam: "Bir şeyin gerçekleşeceğini, olabileceğini hiç düşünmemek",
  },
  {
    deyim: "Kendini kaptırmak",
    anlam: [
      "Bir şeyin etkisinden kurtulamayacak duruma düşmek",
      "Uğraşmaya başladığı bir işten kendini kurtaramamak",
    ],
  },
  {
    deyim: "Gözleri açılmak",
    anlam: [
      "Uyanmak",
      "İyiyi kötüyü veya kendisine yarayanı ayırt eder duruma gelmek",
    ],
  },
  {
    deyim: "Rehin almak",
    anlam: [
      "Bir anlaşma, sözleşme veya isteğin yerine getirilmesini sağlamak için bir kimseyi alıkoymak",
      "Birini aşırı derecede meşgul etmek, oyalamak",
    ],
  },
  { deyim: "Ödü patlamak", anlam: "Çok korkmak" },
  { deyim: "Büyüklük göstermek", anlam: "Gönül ululuğu göstermek" },
  { deyim: "Saygı göstermek", anlam: "Saymak, değer vermek" },
  { deyim: "Fırsat bulmak", anlam: "Uygun, elverişli zaman bulmak" },
  { deyim: "İş çevirmek", anlam: "Gizli, dolambaçlı bir iş yapmak" },
  { deyim: "Hiddete kapılmak", anlam: "Öfkelenmek, kızmak" },
  {
    deyim: "Karar vermek",
    anlam: "Bir sorunu karara bağlamak, kararlaştırmak",
  },
  {
    deyim: "Hayal kurmak",
    anlam: "Gerçekleşmesi istenen, özlenen şeyi düşünmek",
  },
  { deyim: "Kesintiye uğramak", anlam: "Bir süre için durmak" },
  { deyim: "Ümidini kesmek", anlam: "Umudunu kesmek" },
  { deyim: "İmza atmak", anlam: "İmzalamak" },
  {
    deyim: "Damgasını vurmak",
    anlam: "Biri hakkında kötü bir yargıya varmak",
  },
  {
    deyim: "Dile getirmek",
    anlam: ["Konuşturmak", "Belirtmek, anlatmak, açıklamak, ifade etmek"],
  },
  { deyim: "Nabız yoklamak", anlam: "Nabzını yoklamak" },
  {
    deyim: "Mesaj vermek",
    anlam: "Duygu ve düşünceleri karşı tarafa dolaylı bir biçimde anlatmak",
  },
  {
    deyim: "Dalga geçmek",
    anlam: [
      "Argo üzerinde durulması gereken işle ilgilenmeyerek başka şeyler düşünmek veya yapmak",
      "Eğlenmek, alay etmek",
      "Geçici sevgi ilişkisi kurmak, gönül eğlendirmek",
    ],
  },
  { deyim: "Geri dönmek", anlam: "Geldiği yere gitmek" },
  { deyim: "Selam etmek", anlam: "Uzakta olan birine esenlik dilemek" },
  {
    deyim: "Ağır basmak",
    anlam: [
      "Taşıdığı özellikler üstün gelmek",
      "Bir işte gücü ve etkisi üstün gelmek",
    ],
  },
  {
    deyim: "Dikkate almak",
    anlam: "Göz önünde bulundurmak, hesaba katmak, gereğini düşünmek",
  },
  { deyim: "Layık görmek", anlam: "Yakıştırmak, uygun görmek" },
  {
    deyim: "Ortaya koymak",
    anlam: [
      "Herkesin görebileceği yere koymak",
      "Yaratmak, yapmak",
      "Açıklamak",
    ],
  },
  { deyim: "Gurur duymak", anlam: "Gururlanmak" },
  {
    deyim: "Yola çıkmak",
    anlam: [
      "Araca binmek üzere yolüstünde durmak",
      "Bir yere varmak için bulunduğu yerden ayrılarak yolculuğa başlamak, harekete geçmek",
      "Herhangi bir şeyi esas almak, oradan başlamak",
    ],
  },
  {
    deyim: "İlgisini çekmek",
    anlam: "İlgisini, dikkatini ve merakını üzerinde toplamak, alaka duymak",
  },
  {
    deyim: "Tıka basa doldurmak",
    anlam: "Doldururken çok bastırıp sıkıştırmak",
  },
  {
    deyim: "Özen göstermek",
    anlam:
      "Bir şeyi özenerek elden geldiğince iyi olmasına gayret ederek yapmak, itina etmek",
  },
  {
    deyim: "Belini kırmak",
    anlam: "Birini bir şeyi yapamaz duruma getirmek",
  },
  { deyim: "Belini bükmek", anlam: "Çaresizlik içinde bırakmak" },
  { deyim: "Belini doğrultmak", anlam: "Yeniden durumunu düzeltmek" },
  {
    deyim: "Bel bağlamak",
    anlam: "Birisinin kendisine yardımcı olacağına inanmak, güvenmek",
  },
  { deyim: "İple çekmek", anlam: "Sabırsızlıkla beklemek" },
  { deyim: "Tadına varmak", anlam: "Bir şeydeki ince güzelliği kavramak" },
  {
    deyim: "Tereyağından kıl çeker gibi",
    anlam: [
      "Her türlü mecburiyetten, mükellefiyetten ve sorumluluktan kolayca sıyrılarak",
      "Bir işi kolayca yaparak, becerikli bir biçimde",
    ],
  },
  {
    deyim: "Bam teline dokunmak",
    anlam: "Birinin çok kızacağı şeyi yapmak veya sözü söylemek",
  },
  {
    deyim: "Mil çekmek",
    anlam: "Birinin gözlerini kızgın mille kör etmek",
  },
  {
    deyim: "Can vermek",
    anlam: [
      "Ölmek",
      "Ruha güç vermek",
      "Canlanmasına yol açmak",
      "Bir şeyi çok istemek",
    ],
  },
  { deyim: "Kafa bulmak", anlam: "Alay etmek" },
  { deyim: "Burnunda tütmek", anlam: "Çok özlemek" },
  {
    deyim: "Tadına doyum olmamak",
    anlam: [
      "Bir şeyin tadı çok beğenilmek",
      "(Mecaz) Herhangi bir şey çok beğenilmek",
    ],
  },
  {
    deyim: "Göz kamaştırmak",
    anlam: [
      "Kuvvetli ışık veya parlaklık, kısa bir zaman için görüşü bulandırmak",
      "(Mecaz) Bir niteliğiyle hayran bırakmak",
    ],
  },
  {
    deyim: "Yol kesmek",
    anlam: [
      "Geçmesine engel olmak, durdurmak",
      "Iıssız yerlerde soygunculuk yapmak",
      "Motor vb. hızını azaltmak, devrini düşürmek",
    ],
  },
  { deyim: "Göz açıp kapayıncaya kadar", anlam: "Çok kısa bir sürede" },
  { deyim: "Yenik düşmek", anlam: "Yenilmek, mağlup olmak" },
  {
    deyim: "Dikiş tutturamamak",
    anlam: "Bir işte veya bir yerde herhangi bir sebeple uzun süre kalmamak",
  },
  {
    deyim: "Kendi derdine düşmek",
    anlam: "Kendi sorunu sebebiyle başka şeyle ilgilenememek",
  },
  {
    deyim: "Burnundan kıl aldırmamak",
    anlam: "Kendisine söz söyletmemek, çok huysuz ve kibirli olmak",
  },
  {
    deyim: "Bir baltaya sap olamamak",
    anlam: "Belli bir iş sahibi olamamak",
  },
  {
    deyim: "Yüzüne gülmek",
    anlam: [
      "Dostmuş gibi görünmek",
      "Dostluk göstermek, ilgi göstermek, alakalanmak",
      "Temizliği, yeniliği dolayısıyla ferahlık vermek",
    ],
  },
  {
    deyim: "Taş kesilmek",
    anlam:
      "Çok şaşırıp ne yapacağını ne söyleyeceğini bilememek, sesini çıkaramaz olma",
  },
  { deyim: "Tetikte durmak", anlam: "Her an uyanık ve hazır bulunmak" },
  { deyim: "Yol almak", anlam: "Yolda ilerlemek" },
  {
    deyim: "Yer almak",
    anlam: [
      "Bir işi hazırlayanlar arasında bulunmak",
      "Ayrılan yerde durmak, bulunmak",
    ],
  },
  {
    deyim: "Kapı açmak",
    anlam: [
      "Bir şeyin sözünü etmek veya bir işe başlamak",
      "Pazarlığa çok yüksek bir fiyatla başlamak",
    ],
  },
  { deyim: "Hatır saymak", anlam: "Gerekli saygıyı göstermek" },
  {
    deyim: "Gönül almak",
    anlam: [
      "Sevindirmek",
      "Kırılan bir kimseyi güzel bir davranışla hoşnut etmek",
    ],
  },
  {
    deyim: "Başı çekmek",
    anlam: "Herhangi bir konuda önde gitmek, önayak olmak",
  },
  { deyim: "Hatırından geçmemek", anlam: "Aklına gelmemek, düşünmemek" },
  {
    deyim: "Kol gezmek",
    anlam: [
      "Güvenlik amacıyla dolaşmak",
      "Dolaşmak",
      "(Mecaz) Kötü durum ve davranışlar çokça olmak",
    ],
  },
  {
    deyim: "Kuş uçurtmamak",
    anlam: "Hiçbir şeyin veya kimsenin kaçmasına, geçmesine imkân vermemek",
  },
  { deyim: "Yuvarlanıp gitmek", anlam: "Eldeki imkânlarla geçinmek" },
  {
    deyim: "Kafasına estiği gibi",
    anlam: "Sadece kendi düşünce ve isteklerine göre",
  },
  {
    deyim: "Gözden geçirmek",
    anlam: [
      "Okumak",
      "Niteliğini anlamak için bir şeyin her yanına bakmak, incelemek, muayene etmek",
      "Araç, motor vb.nin çalışıp çalışmadığını incelemek, denemek, denetlemek",
    ],
  },
  {
    deyim: "Ölüm kalım savaşına yapmak",
    anlam: "Yok olmamak amacıyla mücadeleye girişmek",
  },
  { deyim: "İşe koşmak", anlam: "Birine iş yaptırmak" },
  {
    deyim: "Göz açtırmamak",
    anlam: "Başka bir iş yapmasına vakit veya imkân vermemek",
  },
  {
    deyim: "Alabora olmak",
    anlam: [
      "Tekne, sandal vb. deniz araçları devrilip ters dönmek",
      "Mecaz işler altüst olmak",
    ],
  },
  { deyim: "Para etmek", anlam: "Değeri olmak" },
  {
    deyim: "İliğine işlemek",
    anlam: [
      "Çok ıslanmak",
      "Çok üşümek",
      "Bütün varlığını kaplamak, çok etkilenmek",
    ],
  },
  { deyim: "Hiçe saymak", anlam: "Önemsememek, önem vermemek" },
  {
    deyim: "Gün doğmak",
    anlam:
      "İsteklerini gerçekleştirmek için iyi bir duruma erişmek veya eline olağanüstü bir fırsat geçmek",
  },
  { deyim: "Kan bürümek", anlam: "Adam öldürecek kadar öfkelenmek" },
  {
    deyim: "Hesaba katmak",
    anlam: "Dikkate almak, göz önünde bulundurmak",
  },
  {
    deyim: "Kapıya dayanmak",
    anlam: [
      "Gelip çatmak",
      "Bir şey elde etmek için bir yeri, bir kimseyi zorlamak, göz korkutmak",
    ],
  },
  {
    deyim: "Yüz vermemek",
    anlam: ["İlgi, yakınlık göstermemek", "Önemsememek"],
  },
  {
    deyim: "Payını almak",
    anlam: ["Kendine ayrılanı almak", "(Mecaz) Azarlanmak, paylanmak"],
  },
  {
    deyim: "Elinden geleni yapmak",
    anlam: "Gücünün yettiği kadarını yapmak",
  },
  { deyim: "Göğüs germek", anlam: "Bir güçlüğe karşı koymak, dayanmak" },
  {
    deyim: "Dili tutulmak",
    anlam:
      "Sevinç, korku, şaşkınlık vb. sebeplerle birdenbire söz söyleyemez olmak",
  },
  { deyim: "Yolunu gözlemek", anlam: "Gelmesini beklemek" },
  {
    deyim: "Haber almak",
    anlam: "Kendisine bildirilmek, öğrenmek, bilgi edinmek",
  },
  {
    deyim: "İçi titremek",
    anlam: ["Özen göstermek", "Çok üşümek", "Duygulanmak"],
  },
  {
    deyim: "Tadı damağında kalmak",
    anlam: [
      "Yenen bir şeyin tadını unutamamak",
      "(Mecaz) Hoşa giden, zevk alınan bir şeyi unutamamak",
    ],
  },
  {
    deyim: "Gözüne uyku girmemek",
    anlam: ["Uyuyamamak", "Uykusuz kalmak"],
  },
  { deyim: "Can atmak", anlam: "Şiddetle arzu etmek" },
  {
    deyim: "Kalp kırmak",
    anlam: ["Birini çok üzecek bir davranışta bulunmak", "Gücendirmek"],
  },
  {
    deyim: "Göz önünde bulundurmak",
    anlam: [
      "Herhangi bir durumun nasıl bir sonuca yol açacağını hesaba katmak",
      "Dikkate almak",
    ],
  },
  {
    deyim: "İsyan etmek",
    anlam: ["Başkaldırmak", "Kabullenmemek", "Razı olmamak"],
  },
  {
    deyim: "Para babası",
    anlam: ["Parası çok, varlıklı kimse"],
  },
  {
    deyim: "Boş kalmak",
    anlam: ["Kimse oturmamak", "İşsiz kalmak", "Tarla ekilmemiş olmak"],
  },
  {
    deyim: "Kulağı kirişte olmak",
    anlam: ["Söylenecek sözü, gelecek haberi sabırsızlıkla beklemek"],
  },
  {
    deyim: "Kucak açmak",
    anlam: ["Korumak", "Sığınacak yer vermek"],
  },
  {
    deyim: "Vakit geçirmek",
    anlam: ["Oyalanmak", "Uğraşmak"],
  },
  {
    deyim: "Bedel ödemek",
    anlam: [
      "Yapılan bir iş veya alınan bir şey karşılığında para ödemek",
      "Yaptığı hatanın karşılığını ödemek, cezasını çekmek",
    ],
  },
  {
    deyim: "Dünyadan haberi olmamak",
    anlam: ["Çevresinde olup bitenleri bilmemek"],
  },
  {
    deyim: "Destek olmak",
    anlam: ["Güç sağlamak", "Yardımcı olmak"],
  },
  {
    deyim: "Çocuk yetiştirmek",
    anlam: ["Çocuğu topluma yararlı bir duruma getirmek"],
  },
  {
    deyim: "Dikkat etmek",
    anlam: [
      "Duygularla düşünceyi bir şey üzerinde toplamak",
      "Uyanık davranmak",
      "Gözüne çarpmak veya ilgisini çekmek",
      "Özen göstermek",
    ],
  },
  {
    deyim: "İfade etmek",
    anlam: ["Anlatmak"],
  },
  {
    deyim: "Yargıya varmak",
    anlam: [
      "Karşılaştırma ve değerlendirme yaparak bir sonuca ulaşmak",
      "Anlam vermek",
    ],
  },
  {
    deyim: "Özen göstermek",
    anlam: [
      "Bir şeyi özenerek elden geldiğince iyi olmasına gayret ederek yapmak",
      "Itina etmek",
    ],
  },
  {
    deyim: "Başı dönmek",
    anlam: [
      "İnsana eşyanın dönmesi, ayağının altından yerin çekilmesi vb. bir duygu gelmek",
      "Sıkıntı yaratan bir durum karşısında bunalmak",
      "Görkemli bir şey karşısında hayranlık duymak",
      "Para veya makam sebebiyle şaşırıp şımarmak",
    ],
  },
  {
    deyim: "Şaşırıp kalmak",
    anlam: ["Çok şaşırmak", "Büyük bir şaşkınlığa düşmek"],
  },
  {
    deyim: "Kan ter içinde kalmak",
    anlam: ["Çok terli, yorgun ve perişan bir durumda olmak"],
  },
  {
    deyim: "Karar vermek",
    anlam: ["Bir sorunu karara bağlamak", "Kararlaştırmak"],
  },
  {
    deyim: "Yol açmak",
    anlam: [
      "Yol yapmak",
      "Kapanmış olan yolu geçilir duruma getirmek",
      "Kalabalık bir yerde genellikle saygıdeğer bir kişinin geçmesi için insanları kenara çekip yol vermek",
      "Mecaz bir olayın sebebi olmak",
      "Mecaz davranışlarıyla başkalarına örnek olmak",
    ],
  },
  {
    deyim: "İşgal etmek",
    anlam: [
      "Bir yeri ele geçirmek",
      "Bir yeri geçici bir süre için elinde tutmak",
      "Eskimiş işten alıkoymak, oyalamak",
      "Eskimiş uğraştırmak",
      "Yer tutmak, kaplamak",
    ],
  },
  {
    deyim: "El koymak",
    anlam: [
      "Bir yolsuzluğu ortaya çıkarmak, incelemek, vaziyet etmek",
      "Üstüne konmak",
      "Zorla almak",
      "İşi üzerine almak, sorumluluğu üstlenmek",
      "Yetkili organ bir malı veya bir kuruluşu kendi yönetimine almak",
    ],
  },
  {
    deyim: "Çağ açmak",
    anlam: [
      "Herhangi bir bakımdan öncekilerden farklı olan yeni bir evrensel gidişe yol açmak",
    ],
  },
  {
    deyim: "Kontrol etmek",
    anlam: [
      "Denetlemek",
      "Yoklamak, gözden geçirmek",
      "Egemenliği altında bulundurmak",
    ],
  },
  {
    deyim: "Aramızdan ayrılmak",
    anlam: ["Vefat etmek"],
  },
  {
    deyim: "Kıyamet kopmak",
    anlam: [
      "Kıyamet günü gelmek",
      "Mecaz bir yerde çok gürültü ve telaş olmak",
    ],
  },
  {
    deyim: "Sahip olmak",
    anlam: ["Mülkiyetinde olmak", "Elinde bulundurmak"],
  },
  {
    deyim: "Dehşete kapılmak",
    anlam: ["Çok korkmak"],
  },
  {
    deyim: "Can vermek",
    anlam: [
      "Ölmek",
      "Ruha güç vermek",
      "Canlanmasına yol açmak",
      "Bir şeyi çok istemek",
    ],
  },
  {
    deyim: "Kan kaybetmek",
    anlam: [
      "Herhangi bir nedenle vücuttan çok kan akmak",
      "Mecaz güçsüzleşmek, etkisini kaybetmek",
    ],
  },
  {
    deyim: "Dua etmek",
    anlam: ["Allah'a yalvarmak"],
  },
  {
    deyim: "Hücum etmek",
    anlam: ["Saldırmak"],
  },
  {
    deyim: "Gününü görmek",
    anlam: [
      "Kötü bir sonla karşılaşmak, cezaya çarptırılmak",
      "Çocuklarının iyi, mutlu günlerini görmek",
    ],
  },
  {
    deyim: "Gözlerine inanamamak",
    anlam: [
      "Hiç umulmayan, hiç beklenmeyen bir şeyin görülmesi karşısında şaşırmak",
    ],
  },
  {
    deyim: "Şafak sökmek",
    anlam: ["Güneş doğmadan az önce ortalık aydınlanmaya başlamak"],
  },
  {
    deyim: "Şikâyet etmek",
    anlam: [
      "Birinin yaptığı yanlış bir iş veya davranışı ilgili makama veya daha üst makamdakine bildirmek",
      "Mecaz sızlanmak",
    ],
  },
  {
    deyim: "Akılda tutmak",
    anlam: ["Unutmamak"],
  },
  {
    deyim: "Selam söylemek",
    anlam: [
      "Selamını birine götürmesini söylemek",
      "Birinin gönderdiği selamı başkasına iletmek",
    ],
  },
  {
    deyim: "Önüne geçmek",
    anlam: ["Yolunu kesmek", "Önlemek"],
  },
  {
    deyim: "Destek olmak",
    anlam: ["Güç sağlamak", "Yardımcı olmak"],
  },
  {
    deyim: "Emin olmak",
    anlam: ["Inanmak", "Güvenmek"],
  },
  {
    deyim: "Fedakârlık göstermek",
    anlam: ["Özverisini ortaya koymak"],
  },
  {
    deyim: "Hayatının baharında olmak",
    anlam: ["Hayatının en güzel dönemini yaşıyor olmak"],
  },
  {
    deyim: "Gurur duymak",
    anlam: ["Gururlanmak"],
  },
  {
    deyim: "Geri saymak",
    anlam: ["Geriye doğru saymak"],
  },
  {
    deyim: "Ayak uydurmak",
    anlam: [
      "Yürüyüşte adım atışını başkalarınınkine uydurmak",
      "Ayak açmak",
      "Mecaz kendi gidiş ve davranışını başkasınınkine benzetmek",
    ],
  },
  {
    deyim: "Çok görmek",
    anlam: ["Yadırgamak"],
  },
  {
    deyim: "Tatlı dil",
    anlam: ["Gönül alıcı söz", "Tatlı söz"],
  },
  {
    deyim: "Üstesinden gelmek",
    anlam: ["Bir işi beklenildiği yapmak", "Becermek", "Başarmak"],
  },
  {
    deyim: "Dile getirmek",
    anlam: ["Konuşturmak", "Belirtmek", "Anlatmak", "Açıklamak", "İfade etmek"],
  },
  {
    deyim: "Dile gelmek",
    anlam: [
      "Dile düşmek",
      "Konuşma kudreti, yeteneği olmayan varlık konuşmak, dillenmek",
    ],
  },
  {
    deyim: "Tadını çıkarmak",
    anlam: [
      "Bir şeyin güzelliğinden veya sağladığı imkânlarından yeterince yararlanmak",
    ],
  },
  {
    deyim: "Dizlerini dövmek",
    anlam: ["Pişmanlık duymak"],
  },
  {
    deyim: "Yalayıp yutmak",
    anlam: [
      "Iştahla yemek",
      "Mecaz kötü bir davranış, söz karşısında ses çıkarmamak, kabullenmek",
    ],
  },
  {
    deyim: "Rayına oturtmak",
    anlam: ["Bir işi yoluna, yöntemine koymak", "Düzgün işler duruma getirmek"],
  },
  {
    deyim: "Paçaları sıvamak",
    anlam: ["Kolları sıvamak"],
  },
  {
    deyim: "Gafil avlamak",
    anlam: [
      "Umulmadık, beklenmedik bir zamanda yakalamak",
      "Zor duruma düşürmek",
    ],
  },
  {
    deyim: "Dilinde tüy bitmek",
    anlam: ["Tekrar tekrar söylemekten usanmak", "Bıkmak"],
  },
  {
    deyim: "Ağzı kulaklarına varmak",
    anlam: ["Çok sevinmek"],
  },
  {
    deyim: "İple çekmek",
    anlam: ["Sabırsızlıkla beklemek"],
  },
  {
    deyim: "Düğün bayram etmek",
    anlam: ["Çok sevinmek", "Neşelenmek"],
  },
  {
    deyim: "İnce eleyip sık dokumak",
    anlam: [
      "Bir şeyi bütün ayrıntılarıyla araştırmak",
      "Gözden veya elden geçirmek",
    ],
  },
  {
    deyim: "Dil dökmek",
    anlam: [
      "Kandırmak",
      "Inandırmak veya yararlanmak için tatlı sözler söylemek",
    ],
  },
  {
    deyim: "Kulak misafiri olmak",
    anlam: ["Yanında konuşulanları hissettirmeden veya istemeden dinlemek"],
  },
  {
    deyim: "Selam durmak",
    anlam: [
      "Bir büyüğe, bir üste veya saygı duyulan bir şeye ayakta selam vermek",
    ],
  },
  {
    deyim: "Ağızdan ağıza",
    anlam: ["Sözlü bir biçimde"],
  },
  {
    deyim: "Yazıya geçirmek",
    anlam: ["Yazmak", "Yazılı duruma getirmek"],
  },
  {
    deyim: "Dert yanmak",
    anlam: [
      "Derdini sızlanarak anlatmak",
      "Bir şeyden şikâyet etmek",
      "Yakınmak",
    ],
  },
  {
    deyim: "Kendini beğenmek",
    anlam: ["Başkalarını küçümseyerek kendini üstün görmek"],
  },
  {
    deyim: "Son vermek",
    anlam: ["Bitirmek", "Sona erdirmek"],
  },
  {
    deyim: "Etkili olmak",
    anlam: ["Etkisi duyulmak", "Etkisini göstermek", "Tesirli olmak"],
  },
  {
    deyim: "Sarıp sarmalamak",
    anlam: ["Sıkıca sarmak"],
  },
  {
    deyim: "Gönül almak",
    anlam: [
      "Sevindirmek",
      "Taltif etmek",
      "Kırılan bir kimseyi güzel bir davranışla hoşnut etmek",
    ],
  },
  {
    deyim: "Dili dönmamak",
    anlam: [
      "Bir sözü doğru, düzgün söylemeyi becerememek",
      "Amacını iyi anlatamamak",
    ],
  },
  {
    deyim: "Tadına doyum olmamak",
    anlam: [
      "Bir şeyin tadı çok beğenilmek",
      "Mecaz herhangi bir şey çok beğenilmek",
    ],
  },
  {
    deyim: "Keyif sürmek",
    anlam: ["Sıkıntısız, rahat yaşamak"],
  },
  {
    deyim: "Söz etmek",
    anlam: ["Bir şey üzerine konuşmak", "Bir şeyin dedikodusunu yapmak"],
  },
  {
    deyim: "Omuz silkmek",
    anlam: ["Aldırmamak", "Önem vermemek"],
  },
  {
    deyim: "Burnunun direği sızlamak",
    anlam: ["Maddi veya manevi çok acı duymak", "Çok üzülmek"],
  },
  {
    deyim: "Siper olmak",
    anlam: [
      "Birini veya bir şeyi korumak amacıyla kendini siper olarak kullanmak",
    ],
  },
  {
    deyim: "Ders vermek",
    anlam: [
      "Öğretmek",
      "Yetiştirmek",
      "Azarlamak",
      "Sert davranmak",
      "Sert bir karşılıkla yola getirmek",
    ],
  },
  {
    deyim: "Yüreği yanmak",
    anlam: ["Çok acımak", "Felakete uğramak"],
  },
  {
    deyim: "Göz yummak",
    anlam: [
      "Görmezlikten gelmek",
      "Hoş görmek",
      "Bağışlamak",
      "Umudunu kesmek",
      "Umutsuzluğa düşmek",
    ],
  },
  {
    deyim: "Diz çökmek",
    anlam: ["Dizlerini yere koyarak oturmak", "Dize gelmek"],
  },
  {
    deyim: "Silip süpürmek",
    anlam: [
      "Evi, ortalığı temizlemek",
      "Ne var ne yoksa hepsini yemek",
      "Ne var ne yok hepsini alıp götürmek veya yok etmek",
    ],
  },
  {
    deyim: "Zarar vermek",
    anlam: ["Kötülük etmek", "Birinin parasal kayba uğramasına sebep olmak"],
  },
  {
    deyim: "Önüne geçmek",
    anlam: ["Yolunu kesmek", "Önlemek"],
  },
  {
    deyim: "Ziyaret etmek",
    anlam: ["Birini görmeye gitmek", "Bir yeri görmeye gitmek"],
  },
  {
    deyim: "Gözünü almak",
    anlam: [
      "Şiddetli ışık sebebiyle gözü iyi göremez duruma getirmek",
      "Mecaz aşırı biçimde etkilenmek",
    ],
  },
  {
    deyim: "Ayna tutmak",
    anlam: [
      "Başkaları tarafından anlaşılabilmesi için açıkça göstermek",
      "Belirtmek",
    ],
  },
  {
    deyim: "Bir kulağından girip öbür kulağından çıkmak",
    anlam: ["Söylenen söze önem vermemek"],
  },
  {
    deyim: "Sözünü tutmak",
    anlam: ["Verdiği sözü yerine getirmek"],
  },
  {
    deyim: "Sağlık olsun",
    anlam: [
      "Üzücü bir durum veya bir zarar karşısında avunma sözü olarak söylenen bir söz",
    ],
  },
  {
    deyim: "Nur topu gibi",
    anlam: ["Sağlıklı, çok güzel ve temiz (çocuk)"],
  },
  {
    deyim: "Turp gibi",
    anlam: ["Sağlığı yerinde"],
  },
  {
    deyim: "Şekerleme yapmak",
    anlam: ["Kısa bir süre uyumak", "Kestirmek"],
  },
  {
    deyim: "Not etmek",
    anlam: ["Not olarak yazmak", "Kaydetmek"],
  },
  {
    deyim: "Ortaya koymak",
    anlam: [
      "Herkesin görebileceği yere koymak",
      "Yaratmak",
      "Yapmak",
      "Açıklamak",
    ],
  },
  {
    deyim: "Geceyi gündüze katmak",
    anlam: ["Aralıksız, gece gündüz çalışmak", "Büyük çaba göstermek"],
  },
  {
    deyim: "Papuç bırakmamak",
    anlam: ["Yapacağından vazgeçmemek", "Hiçbir şeye aldırmamak", "Korkmamak"],
  },
  {
    deyim: "Kendini kaybetmek",
    anlam: [
      "Bayılmak",
      "Aşırı duygulanma dolayısıyla çevrede olup bitenin farkına varamamak",
    ],
  },
  {
    deyim: "Kendine gelmek",
    anlam: ["Ayılmak", "Aklı başına gelmek", "Durumu düzelmek"],
  },
  {
    deyim: "Sözünü kesmek",
    anlam: ["Biri konuşurken söze karışıp onun konuşmasına fırsat vermemek"],
  },
  {
    deyim: "Yolda ilerlemek",
    anlam: ["Yolda ilerlemek"],
  },
  {
    deyim: "İcat etmek",
    anlam: [
      "O zamana kadar kimsenin bilmediği, henüz yapmadığı bir şey yaratmak",
      "Bulmak",
      "Bir şeyi gerçekmiş gibi göstermek",
    ],
  },
];

export default deyimAnlamListesi;
